import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { parse } from "query-string";
import SingleGameScreen from "../SingleGameScreen.js";
import SingleSignIn from "../SingleSignIn";
import styled from "@emotion/styled";

const SingleRound = () => {
  const [gameConfig, setGameConfig] = useState();
  const [signIn, setSignIn] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const query = parse(location.search);
  const params = useParams();
  console.log('location: ', location);
  console.log('query: ', query);
  console.log('history: ', history);
  console.log('params: ', params);
  console.log('round: ', params.round);
  console.log('round stringify: ', JSON.stringify(params));

  if(params.round == 4) {
    localStorage.setItem('round4', 4);
  }
  /*
  if(params.round == 3) {
    localStorage.setItem('round3', 3);
  }
  if(params.round == 2) {
    localStorage.setItem('round2', 2);
  }
  if(params.round == 1) {
    localStorage.setItem('round1', 1);
  }
  */

  const boxShadow =
    "inset 4px 4px 2px rgba(255, 255, 255, .4), inset -4px -4px 2px rgba(0, 0, 0, .4)";

  useEffect(() => {
    fetch("/gameConfig")
      .then((x) => x.json())
      .then((res) => setGameConfig(res));
  }, []);

  return (
    <div>
      {signIn && (
        <SingleSignIn
          setSignIn={setSignIn}
          currentQuestion={Number(params.round - 1)}
          gameConfig={gameConfig}
        />
      )}
      {gameConfig && !signIn && (
        <SingleGameScreen
          gameConfig={gameConfig}
          currentQuestion={Number(params.round - 1)}
          boxShadow={boxShadow}
        />
      )}
    </div>
  );
};

export default SingleRound;
