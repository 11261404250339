import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import * as R from "ramda";
import moment from "moment";

import { useParams } from "react-router-dom";


import UnlockedQRCode from "../../assets/images/Unlocked_QR_Code.gif";

const QRCodeContainer = styled.div`
  background-image: url(${UnlockedQRCode});
  width: 295px;
  height: 295px;
  display: flex;
  margin: 10px 0;
  display: flex;
`;

const CodeContainer = styled.div`
  height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;


const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 540px;
  font-family: Roboto-Bk;
  background-color: #e0301e;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #ffffff;
  border: none;
`;

const HelperText = styled.div`
  width: 540px;
  height: 25px;
  font-family: Roboto-Bk;
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const CorrectIndicator = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  background-color: ${({ correct }) => (correct ? "green" : "red")};
  display: flex;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;

  .checkmark {
    display: inline-block;
    width: 22px;
    height: 22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_stem {
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #ccc;
    left: 11px;
    top: 6px;
  }

  .checkmark_kick {
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #ccc;
    left: 8px;
    top: 12px;
  }
`;

const CodeInput = ({
  code,
  currentQuestion,
  setUnlock,
  userInfo = { team: 0 },
  failed = false,
  setFailed,
  setShowModal,
  boxShadow,
  setShortFailMessage,
  shortcut,
}) => {
  const [code0, setCode0] = useState({ value: "", correct: "" });
  const [code1, setCode1] = useState({ value: "", correct: "" });
  const [code2, setCode2] = useState({ value: "", correct: "" });
  const [code3, setCode3] = useState({ value: "", correct: "" });

  const [submissions, setSubmissions] = useState([]);

  const splitCode = code.split("");

  const submitCode = () => {
    if (code0.value == splitCode[0]) {
      setCode0({ ...code0, correct: true });
    } else {
      setCode0({ ...code0, correct: false });
    }

    if (code1.value == splitCode[1]) {
      setCode1({ ...code1, correct: true });
    } else {
      setCode1({ ...code1, correct: false });
    }

    if (code2.value == splitCode[2]) {
      setCode2({ ...code2, correct: true });
    } else {
      setCode2({ ...code2, correct: false });
    }

    if (code3.value == splitCode[3]) {
      setCode3({ ...code3, correct: true });
    } else {
      setCode3({ ...code3, correct: false });
    }

    fetch("/recordSubmission", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        team: userInfo.team,
        email: userInfo.email,
        question: currentQuestion + 1,
        correct:
          `${code0.value}${code1.value}${code2.value}${code3.value}` === code
            ? true
            : false,
        timestamp: moment.utc(),
      }),
    })
      .then((x) => x.json())
      .then((res) => setSubmissions(res));

    if (`${code0.value}${code1.value}${code2.value}${code3.value}` === code) {
      setUnlock(true);
    }

    if (failed) {
      setUnlock(true);
    } else {
      if (`${code0.value}${code1.value}${code2.value}${code3.value}` === code) {
        setUnlock(true);
        localStorage.setItem("hasPlayed", JSON.stringify({ hasPlayed: false }));
      }
    }
  };

  useEffect(() => {
    fetch(
      "https://pwc-unlocked-submissions.s3.us-east-2.amazonaws.com/submissions/submissions.json"
    )
      .then((x) => x.json())
      .then((data) => setSubmissions(data));
  }, []);

  const groupedData = R.pipe(
    R.groupBy(R.prop("team")),
    R.map(R.groupBy(R.prop("question"))),
    R.map(R.map(R.groupBy(R.prop("correct"))))
  );

  useEffect(() => {
    if (
      groupedData(submissions)[userInfo.team] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1].false &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1].false
        .length >= 5
    ) {
      
    }

    if (
      (code0.value !== "" ||
        code1.value !== "" ||
        code2.value !== "" ||
        code3.value !== "") &&
      groupedData(submissions)[userInfo.team] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1].false &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1].false
        .length > 0 &&
      groupedData(submissions)[userInfo.team] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1].false &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1].false
        .length < 5 &&
      groupedData(submissions)[userInfo.team] &&
      groupedData(submissions)[userInfo.team][currentQuestion + 1] &&
      !groupedData(submissions)[userInfo.team][currentQuestion + 1].true
    ) {
     
    }
  }, [submissions]);

  console.log(groupedData(submissions,'this is the cause')); // testing autofill issue on game load
  // console.log("short fail message", shortFailMessage);

  return (
    <CodeContainer>
      <InputContainer> 
        <QRCodeContainer>

        </QRCodeContainer>
      </InputContainer>
      <SubmitButton
        style={{ boxShadow: boxShadow }}
        disabled={
          code0.value == "" ||
          code1.value == "" ||
          code2.value == "" ||
          code3.value == ""
        }
      >
        SCAN TO UNLOCK CODE
      </SubmitButton>
      <HelperText> </HelperText>
    </CodeContainer>
  );
};

export default CodeInput;
