import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import styled from "@emotion/styled";
import * as R from "ramda";

import SignInBackground from "../assets/images/signin-background.jpg";
import ArrowDown from "../assets/icons/arrowdown.png";

const Container = styled.div`
  background-color: gray;
  background-image: url(${SignInBackground});
  background-size: cover;
  background-position:center center;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`;

const TeamInput = styled.input`
  margin-top: 15px;
  border-radius: 4.5px;
  border: none;
  height: 40px;
  font-size: 32px;
  font-family: Roboto-Bk;
`;

const InputLabel = styled.label`
  font-size: 32px;
  margin-top: 15px;
  font-family: GovtAgent;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Roboto-Bk;
`;

const SubmitButton = styled.button`
  width: 405px;
  height: 50px;
  font-size: 36px;
  background-color: #e0301e;
  border: none;
  margin-top: 15px;
  color: white;
  font-family: Roboto-Bk;
  cursor: pointer;
`;

const DropDownContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4.5px;
  height: 40px;
  margin-top: 15px;
`;

const DropDownHeader = styled.div`
  font-size: 32px;
  color: #000000;
  height: 40px;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropDownListContainer = styled.div`
  position: relative;
  z-index: 100;
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #000000;
  font-size: 1.3rem;
  font-weight: 500;
  max-height: 200px;
  overflow: auto;

  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;

  &:hover {
    color: #808080;
  }
`;

const HeaderContainer = styled.div`
  font-size: 150px;
  font-family: VarietyKiller;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 500px;
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TeamSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const EmailContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const PasswordContainer = styled.div`
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 50px;
`;

const SingleSignIn = ({ gameConfig, setSignIn, currentQuestion }) => {
  const teamNumbers = gameConfig && gameConfig.teams.map((team) => team.id);

  const [teamNumber, setTeamNumber] = useState("");
  const [correctPassword, setCorrectPassword] = useState(false);
  const [password, setPassword] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  const history = useHistory();

  const submitTeam = (e) => {
    e.preventDefault();
    localStorage.setItem(
      "userInfo",
      JSON.stringify({ userInfo: { team: teamNumber, email } })
    );

    setSignIn(false);
  };

  return (
    <Container>
      {gameConfig && (
        <React.Fragment>
          <HeaderContainer>
            <div
              style={{
                fontFamily: "GovtAgent",
                fontSize: "24px",
                width: "100%",
                marginBottom: "-25px",
              }}
            >
              CYBERSECURITY, RISK & REGULATORY
            </div>
            <div>UNLOCKED</div>
          </HeaderContainer>
          {correctPassword ? (
            <Form onSubmit={submitTeam}>
              <FormTopContainer>
                <TeamSelectContainer>
                  <InputLabel for="team">Select Your Group Number:</InputLabel>
                  {/* <TeamInput
            maxLength={2}
            name="team"
            value={teamNumber}
            onChange={(e) => setTeamNumber(e.target.value)}
          /> */}
                  <DropDownContainer>
                    <DropDownHeader
                      onClick={() => setIsOpen((oldState) => !oldState)}
                    >
                      <div>{teamNumber ? teamNumber : "Group Number"}</div>
                      <img
                        style={{ height: "30px", paddingRight: "1rem" }}
                        src={ArrowDown}
                      />
                    </DropDownHeader>
                    {isOpen && (
                      <DropDownListContainer>
                        <DropDownList>
                          {teamNumbers &&
                            teamNumbers.map((item) => (
                              <ListItem
                                onClick={() => {
                                  setTeamNumber(item);
                                  setIsOpen(false);
                                }}
                              >
                                Group {item}
                              </ListItem>
                            ))}
                        </DropDownList>
                      </DropDownListContainer>
                    )}
                  </DropDownContainer>
                </TeamSelectContainer>
                <EmailContainer>
                  <InputLabel for="team">Enter Your Email Address:</InputLabel>
                  <TeamInput
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </EmailContainer>
              </FormTopContainer>
              <SubmitContainer>
                <SubmitButton
                  disabled={!teamNumbers.includes(Number(teamNumber))}
                  type="submit"
                >
                  SUBMIT
                </SubmitButton>
              </SubmitContainer>
            </Form>
          ) : (
            <PasswordContainer>
              <EmailContainer>
                <InputLabel for="team">Enter The Password:</InputLabel>
                <TeamInput
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </EmailContainer>
              <SubmitContainer>
                <SubmitButton
                  onClick={() =>
                    password === gameConfig.rounds[currentQuestion].password
                      ? setCorrectPassword(true)
                      : alert("Incorrect Password")
                  }
                > { console.log("This is the submit area") }
                  SUBMIT
                </SubmitButton>
              </SubmitContainer>
            </PasswordContainer>
          )}
        </React.Fragment>
      )}
    </Container>
  );
};

export default SingleSignIn;
