import React from "react";
import styled from "@emotion/styled";

const ModalContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  text-align: center;
  z-index: 100;
`;

const EmailContainer = styled.textarea`
  font-size: 16px;
  width: 80%;
  height: 100%;
  padding: 15px;
`;

const QuestionItem = styled.div`
  font-size: 24px;
  margin: 25px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  color: #e0301e;
  cursor: pointer;
`;

const Header = styled.div`
  margin-top: 25px;
  font-family: Roboto-Bk;
`;

const EmailTextContainer = styled.div`
  padding: 15px;
  width: 80%;
  font: medium -moz-fixed;
  font: -webkit-small-control;
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  font-size: 16px;
  text-align: left;
  background-color: white;
  color: black;
  overflow: auto;
`;

const EmailModal = ({ email, setShowEmail }) => {
  return (
    <ModalContainer>
      <CloseButton onClick={() => setShowEmail(false)}>X</CloseButton>
      <Header>EMAIL</Header>
      {/* <EmailContainer
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        value={email}
      /> */}
      <EmailTextContainer
        dangerouslySetInnerHTML={{ __html: email }}
      ></EmailTextContainer>
    </ModalContainer>
  );
};

export default EmailModal;
