import React, { useState, useEffect } from "react";
import "babel-polyfill";

import GameScreen from "./components/GameScreen";
import SignIn from "./components/SignIn";
import SingleRound from "./components/SingleRound";
import Countdown from "react-countdown";
import ReactPlayer from "react-player";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import moment from "moment";

import CountdownView from "./components/CountdownView";
import NewsVideo from "./assets/videos/transition-news.mp4";
import AfterCountdownMusic from "./assets/music/after-countdown.mp3";

// import gameConfig from "./gameConfig.json";

const FinalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100vh;
  font-size: 64px;
  font-family: GovtAgent;
  color: white;
`;

const SkipButton = styled.div`
  position: absolute;
  bottom: 40px;
  right: 50px;
  height: 50px;
  width: 200px;
  font-family: Roboto-Bk;
  font-size: 28px;
  color: white;
  background-color: #e0301e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  margin-top: 100px;
  font-size: 150px;
  font-family: VarietyKiller;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function App() {
  const history = useHistory();
  const location = useLocation();
  const [gameConfig, setGameConfig] = useState();
  const [utcTime, setUtcTime] = useState();

  useEffect(() => {
    //if the user hasn't selected their team yet, push them to the sign-in page
    // if (!JSON.parse(localStorage.getItem("userInfo"))) {
    //   history.push("/sign-in");
    // }

    if (JSON.parse(localStorage.getItem("currentQuestion"))) {
      console.log("setting from localstore");
      setCurrentQuestion(
        JSON.parse(localStorage.getItem("currentQuestion")).currentQuestion
      );
    } else {
      localStorage.setItem(
        "currentQuestion",
        JSON.stringify({ currentQuestion: 0 })
      );
      console.log("setting to 0");
      setCurrentQuestion(0);
    }

    if (gameConfig) {
      setUtcTime(
        gameConfig.rounds[
          JSON.parse(localStorage.getItem("currentQuestion")).currentQuestion
        ]
          ? gameConfig.rounds[
              JSON.parse(localStorage.getItem("currentQuestion"))
                .currentQuestion
            ].startTime
          : gameConfig.rounds[gameConfig.rounds.length - 1].startTime
      );
    }
    
  }, [location]);

  useEffect(() => {
    fetch("/gameConfig")
      .then((x) => x.json())
      .then((res) => {
        setGameConfig(res);

        if (!JSON.parse(localStorage.getItem("userInfo"))) {
          const now = moment().utc();
          if (now.isBefore(moment(res.rounds[0].endTime).utc().local())) {
            setCurrentQuestion(0);
            setUtcTime(res.rounds[0].startTime);
            localStorage.setItem(
              "currentQuestion",
              JSON.stringify({ currentQuestion: 0 })
            );
          } else if (
            now.isBetween(
              moment(res.rounds[0].endTime).utc().local(),
              moment(res.rounds[1].endTime).utc().local()
            )
          ) {
            setCurrentQuestion(1);
            setUtcTime(res.rounds[1].startTime);
            localStorage.setItem(
              "currentQuestion",
              JSON.stringify({ currentQuestion: 1 })
            );
          } else if (
            now.isBetween(
              moment(res.rounds[1].endTime).utc().local(),
              moment(res.rounds[2].endTime).utc().local()
            )
          ) {
            setCurrentQuestion(2);
            setUtcTime(res.rounds[2].startTime);
            localStorage.setItem(
              "currentQuestion",
              JSON.stringify({ currentQuestion: 2 })
            );
          } else if (
            now.isBetween(
              moment(res.rounds[2].endTime).utc().local(),
              moment(res.rounds[3].endTime).utc().local()
            )
          ) {
            setCurrentQuestion(3);
            setUtcTime(res.rounds[3].startTime);
            localStorage.setItem(
              "currentQuestion",
              JSON.stringify({ currentQuestion: 3 })
            );
          }
        } else {
          console.log("setting from localStorage line 151");
          setCurrentQuestion(
            JSON.parse(localStorage.getItem("currentQuestion")).currentQuestion
          );
        }

        if (JSON.parse(localStorage.getItem("currentQuestion"))) {
          setUtcTime(
            res.rounds[
              JSON.parse(localStorage.getItem("currentQuestion"))
                .currentQuestion
            ]
              ? res.rounds[
                  JSON.parse(localStorage.getItem("currentQuestion"))
                    .currentQuestion
                ].startTime
              : res.rounds[res.rounds.length - 1].startTime
          );
        }
      });

    fetch("/getEmails");
  }, []);

  const [currentQuestion, setCurrentQuestion] = useState(
    JSON.parse(localStorage.getItem("currentQuestion"))
      ? JSON.parse(localStorage.getItem("currentQuestion")).currentQuestion
      : null
  );
  const [showVideo, setShowVideo] = useState(false);
  const [confirmEnter, setConfirmEnter] = useState(false);

  const convertTime = moment.utc(utcTime, "YYYY-MM-DDTHH:mm:ss");
  const localTime = convertTime.local().format("YYYY-MM-DDTHH:mm:ss");

  const boxShadow =
    "inset 4px 4px 2px rgba(255, 255, 255, .4), inset -4px -4px 2px rgba(0, 0, 0, .4)";

  const gameRender = ({ hours, minutes, seconds, completed }) => {
    if (gameConfig && currentQuestion == gameConfig.rounds.length) {
      setConfirmEnter(true);
    }
    if (confirmEnter) {
      if (currentQuestion != gameConfig.rounds.length) {
        setTimeout(() => setShowVideo(true), 10000);
        return (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {JSON.parse(localStorage.getItem("hasPlayed")) &&
            JSON.parse(localStorage.getItem("hasPlayed")).hasPlayed ? (
              history.push("/dashboard")
            ) : (
              <React.Fragment>
                <video
                  style={{
                    position: "fixed",
                    minHeight: "100%",
                    minWidth: "100%",
                    zIndex: "-10",
                  }}
                  autoPlay
                  preload="auto"
                  muted
                  playsinline
                >
                  <source src={NewsVideo} type="video/mp4" />
                </video>

                <audio autoPlay="true">
                  <source src={AfterCountdownMusic} type="audio/mpeg" />
                </audio>
                {showVideo && (
                  <ReactPlayer
                    url={gameConfig.rounds[currentQuestion].video}
                    width="80%"
                    height="80%"
                    playing={true}
                    controls
                    onEnded={() => {
                      localStorage.setItem(
                        "hasPlayed",
                        JSON.stringify({ hasPlayed: true })
                      );
                      setShowVideo(false);
                      history.push("/dashboard");
                    }}
                  />
                )}
              </React.Fragment>
            )}
            <SkipButton
              onClick={() => {
                localStorage.setItem(
                  "hasPlayed",
                  JSON.stringify({ hasPlayed: true })
                );
                setShowVideo(false);
                history.push("/dashboard");
              }}
            >
              SKIP VIDEO
            </SkipButton>
          </div>
        );
      } else if (currentQuestion == gameConfig.rounds.length) {
        return (
          <FinalContainer>
            <HeaderContainer>
              <div
                style={{
                  fontFamily: "GovtAgent",
                  fontSize: "24px",
                  width: "100%",
                  marginBottom: "-25px",
                }}
              >
                CYBERSECURITY, RISK & REGULATORY
              </div>
              <div>UNLOCKED</div>
            </HeaderContainer>
            <div style={{ width: "75%", marginTop: "50px" }}>
              Congratulations on completing<br></br>this round of Unlocked.
            </div>

            <audio autoPlay="true">
              <source src={AfterCountdownMusic} type="audio/mpeg" />
            </audio>
          </FinalContainer>
        );
      }
    } else {
      localStorage.setItem("hasPlayed", JSON.stringify({ hasPlayed: false }));
      return (
        <React.Fragment>
          {gameConfig && (
            <CountdownView
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              boxShadow={boxShadow}
              setConfirmEnter={setConfirmEnter}
              completed={completed}
              userInfo={
                JSON.parse(localStorage.getItem("userInfo"))
                  ? JSON.parse(localStorage.getItem("userInfo")).userInfo
                  : {}
              }
            />
          )}
        </React.Fragment>
      );
    }
  };

  console.log('currentQuestion: ', currentQuestion);
  console.log('localTime: ', localTime);

  return (
    <Switch>
      <Route
        exact
        path="/"
        // CHANGE THIS BACK TO localTime
        render={() => (
          <div
            style={{
              height: "100vh",
              width: "100%",
              backgroundColor: "black",
              fontFamily: "GovtAgent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "36px",
              color: "white",
              flexDirection: "column",
            }}
          >
            Go to
            <br />
            <a
              href="https://pwcunlocked.com/#/round/1"
              style={{ color: "white", textDecoration: "none" }}
            >
              https://pwcunlocked.com/#/round/1
            </a>
          </div>
        )}
      />
      <Route
        exact
        path="/dashboard"
        render={() => (
          <GameScreen
            gameConfig={gameConfig}
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            setShowVideo={setShowVideo}
            boxShadow={boxShadow}
          />
        )}
      />
      <Route path="/round/:round" render={() => <SingleRound />} />
      <Route
        exact
        path="/sign-in"
        render={() => <SignIn gameConfig={gameConfig} />}
      />
    </Switch>
  );
}

export default App;
