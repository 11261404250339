import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import Fade from "react-reveal/Fade";
import * as R from "ramda";

import IconRow from "./IconRow";
import QuestionModal from "./QuestionModal";
import EmailModal from "./EmailModal";

import SingleSignIn from "./SingleSignIn.js";

import CountdownBackground from "../assets/images/countdown-background.png";
import LockBackground from "../assets/images/lock-background.jpg";

import VaultMusic from "../assets/music/after-unlock.mp3";

import VideoIcon from "../assets/icons/video.png";

import CodeInput from "./CodeInput";
import SideBar from "./SideBar";
import PwcIcon from "../assets/icons/image2.png";
import VaultVideo from "../assets/videos/vault.mp4";
import Check from "../assets/images/check.png";
import AfterCountdownMusic from "../assets/music/after-countdown.mp3";
import NewsVideo from "../assets/videos/transition-news.mp4";

import Transcript1 from "../assets/transcripts/Breaking News Video1.pdf";
import Transcript2 from "../assets/transcripts/Breaking News Video2.pdf";
import Transcript3 from "../assets/transcripts/Breaking News Video3.pdf";
import Transcript4 from "../assets/transcripts/Breaking News Video4.pdf";

const Container = styled.div`
  display: flex;
  height: 100vh;
  width:100%;
  color: white;
  background-image: url(${LockBackground});
  background-size: cover;
  justify-content: center;
`;

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const VideoContainer = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  flex-direction: column;
`;

const DeliverableModal = styled.div`
  background-image: url(${CountdownBackground});
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MessageContainer = styled.div`
  height: 25%;
  display: flex;
`;

const TimerContainer = styled.div`
  height: 50%;
  text-align: left;
  font-size: 48px;
  color: #ffffff;
`;

const TimeRemaining = styled.div`
  font-size: 130px;
`;

const ImageContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0301e;

  img {
    width: 100%;
    height: 100%;
  }
`;

const MessageHeader = styled.div`
  width: 100%;
  display: flex;
`;

const MessageWrapper = styled.div`
  height: 100%;
  font-size: 120px;
  background-color: #464646;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: VarietyKiller;
`;

const CheckBox = styled.input`
  -webkit-appearance: none;
  width: 100px;
  height: 100px;
  background: #dedede;
  border-radius: 15px;
  border: 2px white solid;
  margin-right: 25px;

  :checked {
    background-image: url(${Check});
    background-size: contain;
  }
`;

const ContinueButton = styled.button`
  background-color: #e0301e;
  color: white;
  height: 50px;
  width: 150px;
  border: none;
  font-size: 22px;
  cursor: pointer;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 36px;
  margin-left: 25px;
`;

const TextContainer = styled.div`
  font-size: 48px;
  margin-bottom: 25px;
  font-family: GovtAgent;
  display: flex;
  align-items: center;
`;

const FolderLink = styled.a`
  height: 50px;
  width: 150px;
  color: white;
  font-size: 22px;
  background-color: #e0301e;
  text-decoration: none;
  font-family: Roboto-Bk;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;

const GoBackButton = styled.div`
  height: 50px;
  width: 200px;
  color: white;
  font-size: 18px;
  background-color: #e0301e;
  text-decoration: none;
  font-family: Roboto-Bk;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;
`;

const FileIconAndTextContainer = styled.div`
  margin-top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto-Bk;
  font-size: 36px;
  color: white;
`;

const QuestionTextContainer = styled.div`
  background-color: #7d7d7d;
  font-family: Roboto-Bk;
  font-size: 36px;
  height: 75px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoTranscriptIcon = styled.a`
  display: flex;
  width: 75px;
  height: 75px;
  text-decoration: none;
  cursor: pointer;
`;

const FinalContainer = styled.div`
  position: absolute;
  z-index: 500;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100vh;
  font-size: 64px;
  font-family: GovtAgent;
  color: white;
  text-align: center;
`;

const HeaderContainer = styled.div`
  margin-top: 100px;
  font-size: 150px;
  font-family: VarietyKiller;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SkipButton = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 40px;
  right: 50px;
  height: 50px;
  width: 200px;
  font-family: Roboto-Bk;
  font-size: 28px;
  color: white;
  background-color: #e0301e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SingleGameScreen = ({ gameConfig, currentQuestion, boxShadow }) => {
  const [unlock, setUnlock] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo")) &&
      JSON.parse(localStorage.getItem("userInfo")).userInfo
      ? JSON.parse(localStorage.getItem("userInfo")).userInfo
      : {}
  );
  const [failed, setFailed] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [shortFailMessage, setShortFailMessage] = useState(false);
  const [shortcut, setShortcut] = useState(false);
  const [showFinal, setShowFinal] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const [showNewsVideo, setShowNewsVideo] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // console.log('round', JSON.parse(localStorage.getItem("round")));

    if (
      JSON.parse(localStorage.getItem("hasPlayed")) &&
      JSON.parse(localStorage.getItem("hasPlayed")).hasPlayed == false
    ) {
      // history.push("/");
    }
    if (
      JSON.parse(localStorage.getItem("userInfo")) &&
      JSON.parse(localStorage.getItem("userInfo")).userInfo
    ) {
      setUserInfo(JSON.parse(localStorage.getItem("userInfo")).userInfo);
    } else {
      // history.push("/");
    }
    setTimeout(() => setShowVideo(false), 200);
  }, []);

  useEffect(() => {
    if (failed) {
      setFailMessage(true);
      setTimeout(() => setFailMessage(false), 12000);
      setTimeout(() => setFadeIn(true), 500);
      setTimeout(() => setFadeIn(false), 8000);
    }
    if (shortFailMessage) {
      setTimeout(() => setFadeIn(true), 100);
      setTimeout(() => setFadeIn(false), 4000);
    }
  }, [failed, shortFailMessage]);

  const groupedData = R.pipe(R.groupBy(R.prop("id")));
  console.log("short fail message", shortFailMessage);

  return (
    <Container>
      {showFinal && (
        <FinalContainer>
          <HeaderContainer>
            <div
              style={{
                fontFamily: "GovtAgent",
                fontSize: "24px",
                width: "100%",
                marginBottom: "-25px",
              }}
            >
              CYBERSECURITY, RISK & REGULATORY
            </div>
            <div>UNLOCKED</div>
          </HeaderContainer>

          <div style={{ width: "75%", marginTop: "50px" }} onLoad={() => setShowModal(true)}>
            Congratulations on completing<br></br>this round of Unlocked.
          </div>

          <audio autoPlay="true">
            <source src={AfterCountdownMusic} type="audio/mpeg" />
          </audio>

        </FinalContainer>
      )}
      {showVideo && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <React.Fragment>
            <video
              style={{
                position: "fixed",
                minHeight: "100%",
                minWidth: "100%",
                zIndex: "1",
              }}
              autoPlay
              preload="auto"
              muted
              playsinline
            >
              <source src={NewsVideo} type="video/mp4" />
            </video>

            <audio autoPlay="true">
              <source src={AfterCountdownMusic} type="audio/mpeg" />
            </audio>
            {showNewsVideo && (
              <ReactPlayer
                style={{ position: "absolute", zIndex: "2" }}
                url={gameConfig.rounds[currentQuestion].video}
                width="80%"
                height="80%"
                playing={true}
                controls
                onEnded={() => {
                  setShowVideo(false);
                }}
              />
            )}
          </React.Fragment>
          <SkipButton
            onClick={() => {
              setShowVideo(false);
            }}
          >
            SKIP VIDEO
          </SkipButton>
        </div>
      )}
      {unlock && !failed && !shortcut && (
        <React.Fragment>
          <video
            style={{
              position: "fixed",
              minHeight: "100vh",
              minWidth: "100%",
              zIndex: "10",
            }}
            autoPlay
            preload="auto"
            muted
            playsinline
            onEnded={() => {
              console.log('hasPlayed1');
              localStorage.setItem(
                "hasPlayed",
                JSON.stringify({ hasPlayed: false })
              );
              setShowModal(true);
            }}
          >
            <source src={VaultVideo} type="video/mp4" />
          </video>
          <audio autoPlay="true">
            <source src={VaultMusic} type="audio/mpeg" />
          </audio>
        </React.Fragment>
      )}
      {showModal && (
        <DeliverableModal>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "75%",
            }}
          >
            <MessageContainer>
              <MessageHeader>
                <ImageContainer style={{ boxShadow: boxShadow }}>
                  <div
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                  >
                    <img src={PwcIcon} />
                  </div>
                </ImageContainer>
                <MessageWrapper style={{ boxShadow: boxShadow }}>
                  Welcome to UNLOCKED!
                </MessageWrapper>
              </MessageHeader>
            </MessageContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  paddingLeft: "25px",
                }}
              >
                <TextContainer>
                  Have you uploaded your deliverable to this folder?{" "}
                  <FolderLink
                    href={
                      groupedData(gameConfig.teams)[userInfo.team] &&
                      groupedData(gameConfig.teams)[userInfo.team][0].uploadLink
                    }
                    target="_blank"
                  >
                    Folder
                  </FolderLink>
                </TextContainer>
                <FormContainer>
                  <CheckBox
                    type="checkbox"
                    name="confirm"
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <label
                    style={{ marginRight: "50px", fontFamily: "GovtAgent" }}
                    for="confirm"
                  >
                    Yes
                  </label>
                  <ContinueButton
                    disabled={!checked}
                    onClick={() => {
                      localStorage.setItem(
                        "currentQuestion",
                        JSON.stringify({
                          currentQuestion: currentQuestion + 1,
                        })
                      );

                      window.open(
                        "https://pwc.zoom.us/j/97956466717?pwd=WHBDRFk2KzNZZ293b2ZWM0I2YjhOUT09#success",
                        "name",
                        "width=800,height=600"
                      );
                      setShowFinal(true);
                      setShowModal(false);
                    }}
                  >
                    <strong>CONTINUE</strong>
                  </ContinueButton>
                </FormContainer>
                <GoBackButton
                  onClick={() => {
                    setShowModal(false);
                    setUnlock(false);
                    setShortcut(true);
                  }}
                >
                  Go Back to Resources
                </GoBackButton>
              </div>
            </div>
          </div>
          <IconRow boxShadow={boxShadow} />
        </DeliverableModal>
      )}
      <React.Fragment>
        {gameConfig && !showVideo && (
          <React.Fragment>
            <SideBar
              gameConfig={gameConfig}
              currentQuestion={currentQuestion}
              boxShadow={boxShadow}
              setShowQuestion={setShowQuestion}
              setShowEmail={setShowEmail}
              userInfo={userInfo}
            />
            <RightContainer>
              <VideoContainer>
                <ReactPlayer
                  url={gameConfig.rounds[currentQuestion].video}
                  width="100%"
                  height="80%"
                  controls
                />
                <FileIconAndTextContainer style={{ marginTop: "10px" }}>
                  <VideoTranscriptIcon
                    href={require(`../assets/transcripts/Breaking News Video${
                      currentQuestion + 1
                    }.pdf`)}
                    target="_blank"
                    style={{
                      backgroundImage: `url(${VideoIcon})`,
                      backgroundSize: "contain",
                      boxShadow: boxShadow,
                    }}
                  />
                  <QuestionTextContainer style={{ boxShadow: boxShadow }}>
                    VIDEO TRANSCRIPT
                  </QuestionTextContainer>
                </FileIconAndTextContainer>
              </VideoContainer>
              <CodeInput
                code={gameConfig.rounds[currentQuestion].correctCode}
                currentQuestion={currentQuestion}
                setUnlock={setUnlock}
                userInfo={userInfo}
                failed={failed}
                setFailed={setFailed}
                setShortFailMessage={setShortFailMessage}
                setShowModal={setShowModal}
                boxShadow={boxShadow}
                shortcut={shortcut}
              />
            </RightContainer>
            {showQuestion && (
              <QuestionModal
                questions={gameConfig.rounds[currentQuestion].questions}
                setShowQuestion={setShowQuestion}
              />
            )}
            {showEmail && (
              <EmailModal
                email={gameConfig.rounds[currentQuestion].email}
                setShowEmail={setShowEmail}
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </Container>
  );
};

export default SingleGameScreen;
