import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import moment from "moment";

import IconRow from "../IconRow";

import PwcIcon from "../../assets/icons/PwC White.png";
import CountdownBackground from "../../assets/images/countdown-background.png";
import CountdownMusic from "../../assets/music/countdown.wav";
import BlackBackground from "../../assets/images/countdown-background-black.png";
import SpeakerIcon from "../../assets/icons/speaker-gray.png";
import MutedIcon from "../../assets/icons/mute-gray.png";

const CountdownContainer = styled.div`
  background-color: #6f6f6f;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url(${BlackBackground});
  background-position: left center;
  justify-content: space-between;
  overflow: hidden;
`;

const MessageContainer = styled.div`
  height: 50%;
  display: flex;
`;

const TimerContainer = styled.div`
  height: 50%;
  text-align: left;
  font-size: 48px;
  color: #ffffff;
  padding-left: 25px;
  font-family: GovtAgent;
`;

const TimeRemaining = styled.div`
  font-size: 220px;
  font-family: Digital;
`;

const MessageHeader = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
`;

const MessageWrapper = styled.div`
  height: 100%;
  font-size: 120px;
  background-color: #464646;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: VarietyKiller;
`;

const ImageContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0301e;

  img {
    width: 100%;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled.div`
  height: 90px;
  width: 90px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const EnterButton = styled.div`
  cursor: pointer;
  font-family: Roboto-Bk;
  background-color: #e0301e;
  width: 250px;
  padding: 1rem;
  font-size: 36px;
  display: flex;
  justify-content: center;
`;

const CountdownView = ({
  hours,
  minutes,
  seconds,
  boxShadow,
  completed,
  setConfirmEnter,
  userInfo,
}) => {
  const audio = useRef(null);
  const [playAudio, setPlayAudio] = useState(true);

  useEffect(() => {
    if (audio.current) {
      audio.current.play();
      audio.current.muted = false;

      if (!audio.current.paused || audio.current.currentTime) {
        setPlayAudio(true);
      } else {
        setPlayAudio(false);
      }
    }
  }, [audio]);

  useEffect(() => {
    if (playAudio) {
      audio.current.play();
    } else {
      audio.current.pause();
    }
  }, [playAudio]);

  const recordLogin = () => {
    fetch("/recordSubmission", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        team: userInfo.team,
        email: userInfo.email,
        question: 0,
        timestamp: moment.utc(),
      }),
    });
  };

  return (
    <CountdownContainer>
      <div style={{ display: "flex", flexDirection: "column", height: "75%" }}>
        <MessageContainer>
          <MessageHeader>
            <ImageContainer style={{ boxShadow: boxShadow }}>
              <div style={{ width: "125px", height: "100px" }}>
                <img src={PwcIcon} />
              </div>
            </ImageContainer>
            <MessageWrapper style={{ boxShadow: boxShadow }}>
              Welcome to UNLOCKED!
            </MessageWrapper>
          </MessageHeader>
        </MessageContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
              paddingLeft: "25px",
              width: "1024px",
            }}
          >
            <TimerContainer>
              The experience will begin in:
              <br />
              <TimeRemaining>
                <strong>
                  {hours.toString().length === 1 ? `0${hours}` : hours}:
                  {minutes.toString().length === 1 ? `0${minutes}` : minutes}:
                  {seconds.toString().length === 1 ? `0${seconds}` : seconds}
                </strong>
              </TimeRemaining>
              {completed && (
                <EnterButton
                  onClick={() => {
                    setConfirmEnter(true);
                    recordLogin();
                  }}
                >
                  Click to Enter
                </EnterButton>
              )}
            </TimerContainer>
          </div>
        </div>
      </div>
      <IconRow boxShadow={boxShadow} />
      <audio ref={audio} autoPlay="true" loop="true">
        <source src={CountdownMusic} type="audio/wav" />
      </audio>
      <div
        style={{
          position: "absolute",
          bottom: "180px",
          right: "50px",
          height: "50px",
          width: "50px",
        }}
        onClick={() => setPlayAudio((oldState) => !oldState)}
      >
        <img
          src={playAudio ? SpeakerIcon : MutedIcon}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </CountdownContainer>
  );
};

export default CountdownView;
