import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Pulse from "react-reveal/Pulse";
import Jump from "react-reveal/Jump";
import * as R from "ramda";

import EmailIcon from "../../assets/icons/email.png";
import LockIcon from "../../assets/icons/lock.png";
import PwcIcon from "../../assets/icons/image2.png";
import QuestionIcon from "../../assets/icons/question.png";
import RadarIcon from "../../assets/icons/radar.png";

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopContainer = styled.div`
  padding: 30px;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 350px;
  background-color: rgba(64, 64, 64, 0.8);
  margin-top: 10px;
  margin-left: 10px;
`;

const FileListHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const FileListHeader = styled.div`
  background-color: #e0301e;
  font-family: Roboto-Bk;
  height: 75px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 64px;
`;

const ImageContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileListItem = styled.a`
  margin: 10px;
  width: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 28px;
  font-family: GovtAgent;

  :visited {
    background-color: #5f6061;
  }
`;

const FileItemContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`;

const FooterTitleContainer = styled.div`
  font-size: 120px;
  margin-left: 25px;
  margin-right: 25px;
  font-family: VarietyKiller;
`;

const FooterLogoContainer = styled.div`
  width: 75px;
  height: 75px;

  img {
    height: auto;
    width: 100%;
  }
`;

const FooterLockIcon = styled.div`
  width: 75px;
  height: 75px;
  margin-right: -40px;
  margin-bottom: 38px;

  img {
    width: 100%;
    height: auto;
  }
`;

const FileListIconContainer = styled.div`
  display: flex;
  width: 75px;
  height: 75px;
  cursor: pointer;
`;

const ResourceFolderIcon = styled.a`
  display: flex;
  width: 75px;
  height: 75px;
  text-decoration: none;
  cursor: pointer;
`;

const FileIconAndTextContainer = styled.div`
  margin-left: 10px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  font-family: Roboto-Bk;
  font-size: 36px;
  color: white;
`;

const EmailIconContainer = styled.div`
  height: 75px;
  min-width: 75px;
  background: black;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid white;
`;

const ResourceHeader = styled.div`
  height: 80px;
  background-color: #7d7d7d;
  font-family: Roboto-Bk;
  padding-left: 45px;
  font-size: 36px;
  display: flex;
  align-items: center;
`;

const QuestionTextContainer = styled.div`
  background-color: #7d7d7d;
  font-family: Roboto-Bk;
  font-size: 36px;
  height: 75px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SideBar = ({
  gameConfig,
  currentQuestion,
  boxShadow,
  setShowQuestion,
  setShowEmail,
  userInfo,
}) => {
  const [pulseIcon, setPulseIcon] = useState(true);

  const groupedData = R.pipe(R.groupBy(R.prop("id")));

  return (
    <Container>
      <TopContainer>
        <FileListHeaderContainer>
          <FileListHeader style={{ boxShadow: boxShadow }}>
            ROUND {currentQuestion + 1}
          </FileListHeader>
          {pulseIcon ? (
            <Pulse forever>
              <EmailIconContainer
                onClick={() => {
                  setPulseIcon(false);
                  setShowEmail(true);
                }}
                style={{
                  backgroundImage: `url(${EmailIcon})`,
                  backgroundSize: "contain",
                  boxShadow: boxShadow,
                }}
              >
                <Jump forever>
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",
                      height: "25px",
                      width: "25px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Roboto-Bk",
                      border: "1px white solid",
                    }}
                  >
                    1
                  </div>
                </Jump>
              </EmailIconContainer>
            </Pulse>
          ) : (
            <EmailIconContainer
              onClick={() => {
                setPulseIcon(false);
                setShowEmail(true);
              }}
              style={{
                backgroundImage: `url(${EmailIcon})`,
                backgroundSize: "contain",
                boxShadow: boxShadow,
              }}
            />
          )}
        </FileListHeaderContainer>
        <FileIconAndTextContainer>
          <FileListIconContainer
            onClick={() => setShowQuestion(true)}
            style={{
              backgroundImage: `url(${QuestionIcon})`,
              backgroundSize: "contain",
              boxShadow: boxShadow,
            }}
          />
          <QuestionTextContainer style={{ boxShadow: boxShadow }}>
            QUESTIONS TO UNLOCK CODE
          </QuestionTextContainer>
        </FileIconAndTextContainer>
        <FileList style={{ boxShadow: boxShadow }}>
          <ResourceHeader style={{ boxShadow: boxShadow }}>
            RESOURCES
          </ResourceHeader>
          <FileItemContainer>
            <FileListItem
              href={require("../../assets/pdfs/Unlocked_GameOverview.pdf")}
              target="_blank"
            >
              <Triangle />
              <div style={{ marginLeft: "15px" }}>Game Overview</div>
            </FileListItem>
            {gameConfig.rounds[currentQuestion].resources.map((file) => (
              <FileListItem
                className={file.className}
                href={file.url}
                target="_blank"
              >
                <Triangle className={file.className} />
                <div className={file.className} style={{ marginLeft: "15px" }}>
                  {file.text}
                </div>
              </FileListItem>
            ))}
          </FileItemContainer>
        </FileList>
        <FileIconAndTextContainer style={{ marginTop: "10px" }}>
          <ResourceFolderIcon
            href={
              groupedData(gameConfig.teams)[userInfo.team] &&
              groupedData(gameConfig.teams)[userInfo.team][0].uploadLink
            }
            target="_blank"
            style={{
              backgroundImage: `url(${RadarIcon})`,
              backgroundSize: "contain",
              boxShadow: boxShadow,
            }}
          />
          <QuestionTextContainer style={{ boxShadow: boxShadow }}>
            UPLOAD YOUR DELIVERABLE
          </QuestionTextContainer>
        </FileIconAndTextContainer>
      </TopContainer>
      <FooterContainer>
        <Footer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "50px",
            }}
          >
            <FooterLogoContainer>
              <img src={PwcIcon} />
            </FooterLogoContainer>
            <FooterTitleContainer>UNLOCKED</FooterTitleContainer>
          </div>

          <FooterLockIcon
            style={{
              backgroundImage: `url(${LockIcon})`,
              backgroundSize: "contain",
              boxShadow: boxShadow,
            }}
          />
        </Footer>
      </FooterContainer>
    </Container>
  );
};

export default SideBar;
