import React from "react";
import styled from "@emotion/styled";

const ModalContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  text-align: center;
  z-index: 100;
`;

const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const QuestionItem = styled.div`
  font-size: 24px;
  margin: 25px;
  text-align: left;
  font-family: Roboto-Bk;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  color: #e0301e;
  cursor: pointer;
`;

const Header = styled.div`
  margin-top: 25px;
  font-family: Roboto-Bk;
  width: 1500px;
  margin-bottom: 50px;
  font-size:2rem;
`;

const QuestionModal = ({ questions, setShowQuestion }) => {
  return (
    <ModalContainer>
      <CloseButton onClick={() => setShowQuestion(false)}>X</CloseButton>
      <Header>
        Questions: Each answer will be a single digit number. <br /> Use the
        answers to unlock the code for this round.
      </Header>
      <QuestionList>
        {questions.map((question) => (
          <QuestionItem>{question}</QuestionItem>
        ))}
      </QuestionList>
    </ModalContainer>
  );
};

export default QuestionModal;
