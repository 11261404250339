import React from "react";

import styled from "@emotion/styled";

import LockIcon from "../../assets/icons/lock.png";
import Arrow1Icon from "../../assets/icons/arrow1.png";
import Arrow2Icon from "../../assets/icons/arrow2.png";
import ClockIcon from "../../assets/icons/clock.png";
import RadarIcon from "../../assets/icons/radar.png";
import VideoIcon from "../../assets/icons/video.png";
import EmailIcon from "../../assets/icons/email.png";
import TabletIcon from "../../assets/icons/tablet.png";
import IoIcon from "../../assets/icons/io.png";
import ChartIcon from "../../assets/icons/chart.png";

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled.div`
  height: 160px;
  width: 160px;
  max-height:120px !important;

  img {
    width: 100%;
    height: 100%;
  }
`;

const IconRow = ({ boxShadow }) => {
  return (
    <IconContainer>
      <IconWrapper
        style={{
          backgroundImage: `url(${Arrow1Icon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
          width: "180px",
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${Arrow2Icon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
          width: "180px",
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${LockIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${ClockIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${VideoIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${RadarIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${EmailIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${TabletIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${IoIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
          width: "250px",
        }}
      ></IconWrapper>
      <IconWrapper
        style={{
          backgroundImage: `url(${ChartIcon})`,
          backgroundSize: "contain",
          boxShadow: boxShadow,
          width: "200px",
        }}
      ></IconWrapper>
    </IconContainer>
  );
};

export default IconRow;
